import './SuperSort.scss'
import React from 'react'
import ArrowLeft from '../../../../shared/assets/left.svg'
import SVGInline from 'react-svg-inline'

class SuperSort extends React.Component {
    constructor(props) {
        super(props)
        this.renderOptions = this.renderOptions.bind(this)
        this.changeClick = this.changeClick.bind(this)
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    renderOptions(option, index) {
        if (typeof option === 'object') {
            return <option value={option.value} disabled={option.disabled} key={index}>{option.label}</option>
        }
        return <option value={option} key={index}>{option}</option>
    }

    changeClick (event, selectOptionHandler) {
        selectOptionHandler(event.target.value)
        if (event.target && event.target.blur) {
          event.target.blur()
        }
      }

    render() {
        let modifiedSearchLocation = ''
        if (this.props.searchLocation !== null) {
            modifiedSearchLocation = this.props.searchLocation.replace(", USA", "")
            if (modifiedSearchLocation.length > 38) modifiedSearchLocation = modifiedSearchLocation.substring(0, 35) + '...'
        }

        let selectedLocationName = ''
        if (this.props.selectedLocation) selectedLocationName = this.props.selectedLocation.SearchNameOverride ? this.props.selectedLocation.SearchNameOverride : this.props.selectedLocation.Name ? this.props.selectedLocation.Name : this.props.selectedLocation.lbl ? this.props.selectedLocation.lbl : ''
        if (selectedLocationName.length > 42) selectedLocationName = selectedLocationName.substring(0, 39) + '...'
        return (
            <div className='super-sort--container only-desktop'>
                {this.props.flow !== 'mam' && !this.props.isLocationListLoading && !this.props.selectedProvider && !this.props.locationsAtSelectedProvider && this.props.selectedLocation &&
                 <span className='super-sort--details'>
                    {this.props.selectedLocation ? <a onClick={() => {this.props.setSelectedLocation(null, null, 'resetScroll')}}><SVGInline svg={ArrowLeft} className='back-arrow' /></a> : null}
                    {this.props.providersAtLocationCount && this.props.providersAtLocationCount > 1 ? `${this.props.providersAtLocationCount} Providers` : `${this.props.providersAtLocationCount} Provider`} {`at ${selectedLocationName}`}
                </span>
                }
                {this.props.flow === 'mam' && !this.props.isLocationListLoading && !this.props.selectedProvider && !this.props.locationsAtSelectedProvider && this.props.selectedLocation &&
                    <span className='super-sort--details'>
                        {this.props.selectedLocation ? <a onClick={() => { this.props.setSelectedLocation(null, null, 'resetScroll') }}><SVGInline svg={ArrowLeft} className='back-arrow' /></a> : null}
                        {`${this.props.providersAtLocationCount} ${selectedLocationName}`}
                    </span>
                }
                {this.props.selectedProvider && this.props.locationsAtSelectedProvider &&
                    <span className='super-sort--details'>
                        <a onClick={() => { this.props.setSelectedProvider(null, null); this.props.showHiddenMarkers(); this.props.setSelectedLocation(null, null, 'reuse');}}><SVGInline svg={ArrowLeft} className='back-arrow' /></a>
                        {this.props.locationsAtSelectedProvider.length > 1 ? `${this.props.locationsAtSelectedProvider.length} Locations with ${this.props.selectedProvider.title}` : `${this.props.locationsAtSelectedProvider.length} Location with ${this.props.selectedProvider.title}`}
                    </span>
                }


                { this.props.flow === 'mam' && !this.props.selectedLocation && !this.props.selectedProvider && !this.props.locationsAtSelectedProvider && !this.props.isLocationListLoading &&
                    <span className='super-sort--details'>
                        {this.props.filteredProviderCount} Mammography locations found
                    </span>
                }

                {this.props.flow !== 'mam' && !this.props.selectedLocation && !this.props.selectedProvider && !this.props.locationsAtSelectedProvider && !this.props.isLocationListLoading &&
                    <span className='super-sort--details'>
                        {this.props.filteredProviderCount} Providers at {this.props.mapMarkersFAD.length} locations {this.props.fadSearchedBounds ? 'within map area' : this.props.searchLocation ? `near ${modifiedSearchLocation}` : ''}
                    </span>
                }
                {!this.props.selectedProvider && !this.props.locationsAtSelectedProvider && !this.props.isLocationListLoading &&
                    <div className='super-sort--dropdown'>
                        <span>Sorted By:</span>
                        <select className='super-sort--select' value={this.props.currentValue} onChange={(event) => this.changeClick(event, this.props.selectOptionHandler)} disabled={this.props.disabled} aria-label='dropdown' >
                            {this.props.options && this.props.options.length && this.props.options.map(this.renderOptions)}
                        </select>
                    </div>
                }
            </div>
        )
    }
}

export default SuperSort
