import React, { useState, useCallback } from 'react'
import moment from 'moment'
import SVGInline from 'react-svg-inline'
import URLS from '../../../../shared/constants/urls'
import InfoBadge from '../../../../shared/components/InfoBadge'
import StarBar from '../../../../shared/components/StarBar'
import playButton from '../../../../shared/assets/Play_ol.svg'
import LocationIcon from '../../../../shared/assets/map-marker.svg'
import isDesktop from '../../../../shared/utility-functions/isDesktop'
import { isOBGYN } from '../../../../shared/utility-functions/isOBGYN'
import { buildProfilePageUrl, getURLParameters, cleanNumber, launchVideo, addressBuilder, getAgeInYears } from '../../../../shared/utility-functions'
import { insertBrowserHistory } from '../../../state/history'
import './SuperResultsCard.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { clickToCallEvent } from '../../../../shared/utility-functions/googleTagManager'
import ProviderDrawerConnector from '../../DynamicDrawers/ProviderDrawer/ProviderDrawerConnector.jsx'
import TimesDrawer from '../../DynamicDrawers/TimesDrawer'
import ReviewsDrawerConnector from '../../DynamicDrawers/ReviewsDrawer/ReviewsDrawerConnector.jsx'
import SuperResultsTimesConnector from './SuperResultsTimes/SuperResultsTimesConnector.jsx'
import OBGYNQuestionaireConnector from '../../../../Blocks/QuestionnaireModal/OBGYNQuestionaire/OBGYNQuestionaireConnector.jsx'
import { getLocationsForSelectedProvider } from '../../FADMap/FADMapUtils'

const SuperResultsCard = ({ preliminaryStatus, setPreliminaryStatus, provider, distance, pageNumber, profileLinkClick, scheduleLinkClick, options, sort, flow, 
  active = null, onMouseEnter, onMouseLeave = () => { return }, 
  onClickProv, setSelectedProvider, setSelectedLocation, results, setIsLocationListLoading, selectedProvider = null }) => {

  const cardOptions = {
    hidePractice: options && options.hidePractice === true,
    hidePhone: options && options.hidePhone === true,
    hidePhoto: options && options.hidePhoto === true,
    hideScheduling: options && options.hideScheduling === true,
    hideRatings: options && options.hideRatings === true,
    profileRoot: options && options.profileRoot ? options.profileRoot : null,
    displayAvailability: options && options.displayAvailability === true
  }

  const rating = provider.rating
  const hasScheduleOnline = cardOptions.hideScheduling ? false : provider.hasScheduleOnline
  const hasDoctorPhoto = provider.photoUrl != null
  const years = provider.experience ? getAgeInYears(moment(provider.experience, 'DD/MM/YYYY')) : null
  let phone = null
  if (provider) {
    if (provider.locations && provider.locations.length === 1) {
      phone = provider.locations[0].phoneNumber
    } else {
      phone = provider.phone
    }
  }

  let profilePageURL
  if (!cardOptions.profileRoot) {
    let rootUrl = window.location.hostname && window.location.hostname.indexOf('test') > -1 ? 'test.ohiohealth.com' : (window.location.hostname && window.location.hostname.indexOf('dev') > -1 ? 'dev.ohiohealth.com' : (window.location.hostname && window.location.hostname.indexOf('stage') > -1 ? 'stage.ohiohealth.com' : 'ohiohealth.com'))
    profilePageURL = buildProfilePageUrl(`${window.location.protocol}//${window.location.hostname === 'localhost' ? 'localhost' : rootUrl}${URLS.findADoctorUrl}${URLS.profileUrl}/`, provider.title, provider.npi)
  } else {
    profilePageURL = buildProfilePageUrl(`${window.location.protocol}//${cardOptions.profileRoot}/`, provider.title, provider.npi)
  }

  const clickToCall = () => {
    const dataLayerObject = {
      flow: flow,
      providerPhoneNumber: provider.phone,
      providerName: provider.title,
      providerID: provider.id,
      providerNPI: provider.npi,
      providerSpecialty: provider.specialties ? provider.specialties.join(', ') : undefined,
      providerAddress: provider.address ? provider.address.adr + ', ' + provider.address.c + ' ' + provider.address.s + ' ' + provider.address.z : undefined,
      facilityName: undefined,
      facilityAddress: undefined
    }

    clickToCallEvent(dataLayerObject)
  }

  const [showQuestions, setShowQuestions] = useState(false)
  const [providerDrawerShow, setProviderDrawerShow] = useState(false)
  const [timesDrawerShow, setTimesDrawerShow] = useState(false)
  const [reviewsDrawerShow, setReviewsDrawerShow] = useState(false)
  const [fadeOutType, setFadeOutType] = useState('none')
  const [fadeInType, setFadeInType] = useState('none')

  const removeRootScroll = () => {
    document.documentElement.classList.add('no-scroll-y')
  }

  const addRootScroll = () => {
    document.documentElement.classList.remove('no-scroll-y')
  }

  const openDrawer = (type) => {
    removeRootScroll()
    profileLinkClick(provider)
    setProviderDrawerShow(true)
    if (type === 'times') {
      setFadeInType('card')
      setTimesDrawerShow(true)
    } else if (type === 'reviews') {
      setFadeInType('card')
      setReviewsDrawerShow(true)
    }
  }

  const closeAllDrawers = () => {
    setProviderDrawerShow(false)
    setTimesDrawerShow(false)
    setReviewsDrawerShow(false)
    addRootScroll()
  }

  const onDrawerAnimateEnd = () => {
    if (fadeOutType === 'provider') {
      setProviderDrawerShow(false)
      addRootScroll()
    } else if (fadeOutType === 'times') {
      setTimesDrawerShow(false)
    } else if (fadeOutType === 'reviews') {
      setReviewsDrawerShow(false)
    } else if (fadeOutType === 'all') {
      closeAllDrawers()
    }
    setFadeInType('none')
    setFadeOutType('none')
  }

  const profileClick = () => {
    // console.log('clicked')
    if (isDesktop(window.innerWidth)) {
      // console.log('is desktop')
      removeRootScroll()
      profileLinkClick(provider)
      setFadeInType('provider')
      setProviderDrawerShow(true)
    } else if (options && options.enableUrl && sort !== 'Random') {
      insertBrowserHistory(getURLParameters(), pageNumber, provider.npi)
    }
  }

  const renderDrawers = () => {
    if (providerDrawerShow || timesDrawerShow || reviewsDrawerShow) {
      return (
        <span className='drawers'>
          <ProviderDrawerConnector provider={provider} profilePageURL={profilePageURL} distance={distance} options={options} show={providerDrawerShow} cardOptions={cardOptions} preliminaryStatus={preliminaryStatus} setPreliminaryStatus={setPreliminaryStatus}
            fadeOutType={fadeOutType}
            handleClose={() => { setFadeOutType('provider') }}// setProviderDrawerShow(false); addRootScroll() }}
            handleCloseAll={() => { setFadeOutType('all') }}
            onAnimationEnd={() => { onDrawerAnimateEnd() }}
            timesDrawerClick={() => { setFadeInType('drawer'); setTimesDrawerShow(true) }}
            reviewsDrawerClick={() => { setFadeInType('drawer'); setReviewsDrawerShow(true) }}
            timesDrawerShow={timesDrawerShow}
            reviewsDrawerShow={reviewsDrawerShow}
            from={'provider-drawer'}
            setShowQuestions={setShowQuestions}
          />
          <TimesDrawer provider={provider} from={fadeInType} show={timesDrawerShow} scheduleLinkClick={scheduleLinkClick}
            fadeOutType={fadeOutType} flow={flow}
            handleClose={() => { setFadeOutType('times') }}// setTimesDrawerShow(false) }}
            handleCloseAll={() => { setFadeOutType('all') }}// closeAllDrawers() }}
            onAnimationEnd={() => { onDrawerAnimateEnd() }}
            headerContent={'Available Appointments'}
            subHeaderContent={''}
          />
          <ReviewsDrawerConnector from={fadeInType} distance={distance} options={options} show={reviewsDrawerShow} cardOptions={cardOptions}
            fadeOutType={fadeOutType}
            handleClose={() => { setFadeOutType('reviews') }}// setReviewsDrawerShow(false) }}
            handleCloseAll={() => { setFadeOutType('all') }}// closeAllDrawers() }}
            onAnimationEnd={() => { onDrawerAnimateEnd() }}
          />
        </span>
      )
    }
    return null
  }

  const renderDoctorPhoto = () => {
    return hasDoctorPhoto
      ? <LazyLoadImage // use normal <img> attributes as props
        alt={provider.title}
        src={provider.photoUrl}
        onClick={profileClick}
        aria-label={provider.title + ' profile'} 
        width='120' 
        style={{ height: '' }} />
      : <img 
          alt={provider.title}
          src={URLS.defaultPhotoUrl}
          onClick={profileClick}
          aria-label={provider.title + ' profile'}
          className='doc-img default-photo' 
          />
  }

  const renderRatings = () => {
    return (
    // <div className={distance && rating && rating.Rating ? 'ratings-aggregate-component divider' : 'ratings-aggregate-component'}>
      <div className='rating--container'>
        <StarBar rating={rating.Rating} />
        {isDesktop(window.innerWidth)
          ? <a className='reviews-link' onClick={() => { openDrawer('reviews') }}>
            <div className='link-to-reviews'>{rating.Rating} ({rating.NumberOfRatings})</div>
          </a>
          : <a className='reviews-link' href={`${profilePageURL}?rar=true`} target='_self'>
            <div className='link-to-reviews'>{rating.Rating} ({rating.NumberOfRatings})</div>
          </a>
        }
      </div>
    )
  }

  const mapsAddress = (address) => {
    console.log(address)
  }

  let mobileAddressLine = ''
  if (provider.address) {
    mobileAddressLine = provider.address.d ? provider.address.d + ' mi - ' : ''
    mobileAddressLine = mobileAddressLine + provider.address.adr + ', ' + provider.address.c
    if (mobileAddressLine.length > 43) {
        mobileAddressLine = mobileAddressLine.substring(0, 40) + '...'
    }
  }
  
  return (
    <React.Fragment> 
      {!selectedProvider ?
        <div className={`super-results-card--container ${active ? 'active' : ''}`} 
        onClick={onClickProv ? () => onClickProv(provider) : null}
         onMouseEnter={onMouseEnter ? () => onMouseEnter(provider.addresses.map(address => address.id)) : null} 
         onMouseLeave={onMouseLeave ? () => onMouseLeave(provider.addresses.map(address => address.id)) : null}>
          {renderDrawers()}
          <div className='super-results-profile--container'>
            <div className='profile-image--container'>
              {
                isDesktop(window.innerWidth)
                  ? <a className='profile-image--url' id={provider.npi} onClick={profileClick} aria-label={provider.title + ' profile'}>{renderDoctorPhoto()}</a>
                  : <a className='profile-image--url' id={provider.npi} href={profilePageURL} aria-label={provider.title + ' profile'}>{renderDoctorPhoto()}</a>
              }
              {
                provider.videoUrl &&
                <button className='doctor-video' title='Doctor Video' onClick={() => { launchVideo(provider.videoUrl) }}>
                  <div className='icon super-results'><SVGInline accessibilityDesc='Play Video Button' svg={playButton} /></div>
                </button>
              }
            </div>
            <div className='profile-details--container'>
              {provider.isAcceptingPatients && !cardOptions.hidePhoto && <InfoBadge className='doctor-card-badge' text='Accepting New Patients' />}
              {
                isDesktop(window.innerWidth)
                  ? <a className='title-profile-url' onClick={profileClick} target='_self'>
                    <div className='title'>{provider.title}</div>
                  </a>
                  : <a className='title-profile-url' onClick={profileClick} href={profilePageURL} target='_self'>
                    <div className='title'>{provider.title}</div>
                  </a>
              }
              {provider.specialties && provider.specialties.length > 0 && <div className='specialties'>{provider.specialties.join(', ')}</div>}
              {!cardOptions.hideRatings && rating && rating.Rating && renderRatings()}

              {provider && provider.allAddresses && provider.allAddresses.length > 1 && isDesktop(window.innerWidth) &&
                <div className='show-all-locations desktop' onClick={() => { setIsLocationListLoading(true); setSelectedLocation(null, null, 'save'); getLocationsForSelectedProvider(provider, results).then((locations) => { setSelectedProvider(provider, locations) }) }}>Show All Locations</div>
              }

              {
                provider.address &&
                <a className='profile-address' href={`https://maps.apple.com/?q=${provider.address.adr}+${provider.address.c}+${provider.address.s}`} target='_blank'>
                  {provider.address.d ? provider.address.d + ' mi - ' : ''}{provider.address.adr + ', ' + provider.address.c}
                </a>
              }
              {
                !cardOptions.hidePhone && provider.phone &&
                <div className={`phone-number ${!hasScheduleOnline ? 'alone' : ''}`}>
                  <a href={`tel:${cleanNumber(provider.phone)}`} onClick={() => clickToCall()}><span>{provider.phone}</span></a>
                </div>
              }
            </div>
          </div>
          {!isDesktop(window.innerWidth) && (provider.address || (provider.addresses && provider.addresses.length > 1)) &&
            <div className='super-results-address--container outer'>
              <SVGInline className='location-icon' svg={LocationIcon} />
              <div className='super-results-address--container inner'>
              {
                provider.address && !isDesktop(window.innerWidth) &&
                <a className='super-results-address--container-url' href={`https://maps.apple.com/?q=${provider.address.adr}+${provider.address.c}+${provider.address.s}`} target='_blank'>
                  {mobileAddressLine}
                </a>
              }
              {provider.allAddresses.length > 1 && !isDesktop(window.innerWidth) &&
                <div className='show-all-locations mobile' onClick={() => { setIsLocationListLoading(true); setSelectedLocation(null, null, 'save'); getLocationsForSelectedProvider(provider, results).then((locations) => { setSelectedProvider(provider, locations) }) }}>Show All Locations</div>
              }
              </div>
            </div>
          }
          <SuperResultsTimesConnector preliminaryStatus={preliminaryStatus} setPreliminaryStatus={setPreliminaryStatus} provider={provider} profilePageURL={profilePageURL} timesDrawerClick={() => { openDrawer('times') }} handleCloseAll={() => { setFadeOutType('all') }} from={'card'} setShowQuestions={setShowQuestions}  />

          {showQuestions && isOBGYN(provider.specialtyIds) && preliminaryStatus === null &&
            <OBGYNQuestionaireConnector setPreliminaryStatus={setPreliminaryStatus} phone={provider.phone} doctor={provider} setShowQuestions={setShowQuestions} from={'provider-drawer'}/>}
        </div>
        :
        <div className='super-results-card--container selected-provider-container-styles'>
          {renderDrawers()}
          <div className='super-results-profile--container min-height-none'>
            <div className='profile-image--container'>
              {
                isDesktop(window.innerWidth)
                  ? <a className='profile-image--url' id={provider.npi} onClick={profileClick} aria-label={provider.title + ' profile'}>{renderDoctorPhoto()}</a>
                  : <a className='profile-image--url' id={provider.npi} href={profilePageURL} aria-label={provider.title + ' profile'}>{renderDoctorPhoto()}</a>
              }
              {
                provider.videoUrl &&
                <button className='doctor-video height-0' title='Doctor Video' onClick={() => { launchVideo(provider.videoUrl) }}>
                  <div className='icon super-results'><SVGInline accessibilityDesc='Play Video Button' svg={playButton} /></div>
                </button>
              }
            </div>

            <div className='profile-details--container'>
              {provider.isAcceptingPatients && !cardOptions.hidePhoto && <InfoBadge className='doctor-card-badge' text='Accepting New Patients' />}
              {
                isDesktop(window.innerWidth)
                  ? <a className='title-profile-url' onClick={profileClick} target='_self'>
                    <div className='title'>{provider.title}</div>
                  </a>
                  : <a className='title-profile-url' onClick={profileClick} href={profilePageURL} target='_self'>
                    <div className='title'>{provider.title}</div>
                  </a>
              }

              <div className='specialties'>
                {provider.specialties && provider.specialties.length > 0 && <span>{provider.specialties.join(', ')}</span>}
                {
                  years !== null && isDesktop(window.innerWidth) &&
                  <span> • {years <= 1 ? '1 Year of Experience' : `${years} Years of Experience`}</span>
                }
              </div>

              {!cardOptions.hideRatings && rating && rating.Rating && renderRatings()}
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  )
}

export default SuperResultsCard
