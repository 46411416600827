import React from 'react'
import './find-page.scss'
import closeIcon from '../shared/assets/close-icon.svg'
import info from '../shared/assets/info.svg'
import SearchSortConnector from './components/SearchSort'
import SVGInline from 'react-svg-inline'
import SkeletonProviderCard from './components/SearchCards/SkeletonProviderCard'
import SkeletonTableColumn from './components/SearchCards/SkeletonTableColumn'

import SkeletonServiceCard from './components/SearchCards/SkeletonServiceCard'
import ErrorMessageBar from '../shared/components/AlertBar/ErrorMessageBar'
import loadsData from '../shared/components/LoadsData'
import { DOCTOR_ERROR_MESSAGE, LOCATION_ERROR_MESSAGE } from '../shared/constants/messages'
import URLS from '../shared/constants/urls.js'
import SearchBarConnector from './components/SearchBar/SearchBarConnector.jsx'
import BackToTop from '../shared/components/BackToTop'
import NoResultsFoundConnector from './components/NoResultsFound'
import { insertBrowserHistory } from './state/history'
import VideoModal from '../shared/components/VideoModal/VideoModal.jsx'
import { scrollToLocation, insertEmptyFilters, togglePreventScroll, getURLParameters, getParameterByName, GeolocationAcceptOrDeny } from '../shared/utility-functions'
import FindFiltersConnector from './components/Filters/FindFilters/FindFiltersConnector.jsx'
import SimpleFilterConnector from './components/Filters/SimpleFilter/SimpleFilterConnector.jsx'
import searchCount from '../shared/data-mappers/count-string.js'
import MultiLocationMap from '../Blocks/MultiLocationMap/MultiLocationMap.jsx'
import AffiliatedDoctorSection from './components/AffiliatedDoctorSection/AffiliatedDoctorSection.jsx'
import SkeletonMap from '../Blocks/MultiLocationMap/SkeletonMap.jsx'
import FirstAvailModal from './components/FirstAvailableModal/FirstAvailableModal.jsx'
import Pagination from '../shared/components/Pagination/Pagination.jsx'
import FilteredTooFarConnector from './components/FilteredTooFar/FilteredTooFarConnector.jsx'
import FiltersRowConnector from './components/SuperFilters/FiltersRow/FiltersRowConnector.jsx'
import SuperSortConnector from './components/SuperFilters/SuperSort/SuperSortConnector.jsx'
import InfiniteScroll from './components/InfiniteScroll/InfiniteScroll.jsx'
import FADMapConnector from './components/FADMap/FADMapConnector.jsx'
import { hoveredMarkerIconPNG, defaultMarkerIconPNG, selectedLightMarkerIconPNG, createLocationIcon } from '../Search/components/FADMap/FADMapUtils'
import LoadingElement from '../shared/components/LoadingElement/LoadingElement.jsx'
import ArrowLeft from '../shared/assets/left.svg'
import isDesktop from '../shared/utility-functions/isDesktop.js'
import SuperSortMobileConnector from './components/SuperFilters/SuperSortMobile/SuperSortMobileConnector.jsx';

let initialDisplay
export default class FindPage extends React.Component {
  constructor(props) {
    super(props)
    window.sessionStorage.clear();
    initialDisplay = this.props.initialDisplay === undefined ? true : this.props.initialDisplay
    this.props.initializePage()
    if (window.currentDoctor) { window.currentDoctor = null }

    this.state = {
      asideClass: null,
      desktopFixed: false,
      desktopBottom: false,
      innerHeight: window.innerHeight,
      showFilters: this.props.filterOptions ? Object.values(this.props.filterOptions).includes(true) : false,
      dob: false,
      fadPreliminaryStatus: null,
      superResultsListKey: 1,
      mapMarkersFAD: [],
      hiddenMapMarkersFAD: [],
      mapFAD: null,
      markerClustererFAD: null,
      clickedProvider: null,
      clickedLocationCard: null,
      selectedLocation: null,
      previousSelectedLocations: [],
      previousFADMapBounds: null,
      selectedProvider: null,
      locationsAtSelectedProvider: null,
      isLocationListLoading: false,
      selectedMarker: null,
      providersAtLocation: null,
      fadResultsScrollPositions: {main: 0, selectedLocation: 0},
      mobile: !isDesktop(window.innerWidth) || false,
      addMapListeners: false,
      zoomChangedListenerRef: null,
      defaultLocationIcons: [],
      hoveredLocationIcons: [],
      showSuperSortMobile: false
    }

    this.hideBlock = this.hideBlock.bind(this)
    this.clearAllFilters = this.clearAllFilters.bind(this)
    this.setFiltersFromURL = this.setFiltersFromURL.bind(this)
    this.toggleAffiliatedDoctors = this.toggleAffiliatedDoctors.bind(this)
    this.generateDataContent = this.generateDataContent.bind(this)
    this.generateErrorContent = this.generateErrorContent.bind(this)
    this.generateLoadingContent = this.generateLoadingContent.bind(this)
    this.mapContent = this.mapContent.bind(this)
    this.results = this.results.bind(this)
    this.superResults = this.superResults.bind(this)
    this.mobileFilters = this.mobileFilters.bind(this)
    this.mobileSuperFilters = this.mobileSuperFilters.bind(this)
    this.handleStickyElements = this.handleStickyElements.bind(this)
    this.filterOptions = this.props.filterOptions || {}
    this.urlParameters = this.props.urlParameters || { search: {}, filters: {} }
    this.changeDesktopState = this.changeDesktopState.bind(this)
    this.setFADPreliminaryStatus = this.setFADPreliminaryStatus.bind(this)
    this.updateInitialValue = this.updateInitialValue.bind(this)
    this.mapScrollRef = React.createRef()
    this.fadSuperListRef = React.createRef()
    this.handleItemMouseEnter = this.handleItemMouseEnter.bind(this)
    this.handleItemMouseLeave = this.handleItemMouseLeave.bind(this)
    this.updateMarkerStyle = this.updateMarkerStyle.bind(this)
    this.setMarkersState = this.setMarkersState.bind(this)
    this.removeFADMapMarkers = this.removeFADMapMarkers.bind(this)
    this.setClickedProvider = this.setClickedProvider.bind(this)
    this.setClickedLocation = this.setClickedLocation.bind(this)
    this.setSelectedLocation = this.setSelectedLocation.bind(this)
    this.setSelectedProvider = this.setSelectedProvider.bind(this)
    this.setIsLocationListLoading = this.setIsLocationListLoading.bind(this)
    this.preventResultBarDrag = this.preventResultBarDrag.bind(this)
    this.hideFADMapMarkers = this.hideFADMapMarkers.bind(this)
    this.showHiddenMarkers = this.showHiddenMarkers.bind(this)
    this.setMapState = this.setMapState.bind(this)
    this.setMarkerClustererState = this.setMarkerClustererState.bind(this)
    this.setAddMapListenersState = this.setAddMapListenersState.bind(this)
    this.setZoomChangedListenerRef = this.setZoomChangedListenerRef.bind(this)
    this.setFadResultsScrollPositions = this.setFadResultsScrollPositions.bind(this)
    this.setMobile = this.setMobile.bind(this)
    this.updateLocationIconStyle = this.updateLocationIconStyle.bind(this)
    this.incrementSuperResultsListKey = this.incrementSuperResultsListKey.bind(this)
    this.setShowSuperSortMobile = this.setShowSuperSortMobile.bind(this)

    if (getURLParameters().search.app) {
      this.props.toggleAffiliatedDoctors()
    }
    window.addEventListener('resize', this.setMobile, { passive: true })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setMobile)
  }

  componentDidUpdate(prevProps, prevState) {
    const dobString = window.sessionStorage.getItem('qDate') ? true : false;

    if (dobString !== prevState.dob) {
      this.setState({ dob: true })
    }

    if (this.props.filteredProviderCount === 0 && this.props.affiliatedProviderCount > 0 && this.props.dataLoaded && !this.props.fetchCallFailed && !this.props.showAffiliatedProviderMessage) {
      this.props.toggleAffiliatedDoctors()
    }
    if (this.props.flow === 'mam') {
      if (this.props.questionnaireCompleted !== prevProps.questionnaireCompleted) {
        let visitTypeID = JSON.parse(window.sessionStorage.getItem('VisitTypeID'))
        if (visitTypeID) {
          this.props.setVisitType(String(visitTypeID))
          let searchParams = getURLParameters().search
          let search = {}
          for (var param in searchParams) {
            if (searchParams.hasOwnProperty(param) && searchParams[param] !== '' && searchParams[param]) {
              search[param] = searchParams[param]
            }
          }
          this.props.runSearch({ 'VisitTypeId': String(visitTypeID), ...search })
        }
      }
    }
    if (this.props.flow === 'fad' && this.props.searchOptions.isSuperResults && this.props.fadSearchedBounds && ((this.props.allDoctors !== prevProps.allDoctors) || (this.props.autoSearchedLocation !== prevProps.autoSearchedLocation))) {
      this.incrementSuperResultsListKey()
    }
    if (this.props.flow === 'fad' && this.props.searchOptions.isSuperResults && (this.props.allDoctors !== prevProps.allDoctors)) {
      if (!this.props.fadSearchedBounds) {
        this.setSelectedLocation(null, null)
      }
      else {
        this.setSelectedLocation(this.state.selectedLocation, this.state.selectedMarker, 'reset')
      }
      this.setSelectedProvider(null, null)
    }
    else if (this.props.flow === 'fad' && this.props.searchOptions.isSuperResults && this.props.allDoctors === prevProps.allDoctors && this.props.searchItems !== prevProps.searchItems) {
      this.setSelectedLocation(this.state.selectedLocation, this.state.selectedMarker)
    }
  }

  componentDidMount() {
    this.setState({ initialValue: 'default-select' })
    window.addEventListener('scroll', this.handleStickyElements, { passive: true })
    this.setFiltersFromURL()
    window.addEventListener('popstate', this.setFiltersFromURL, { passive: true })
    window.addEventListener('pageshow', () => this.props.hideSchedulingModal())
    this.setMobile();
  }

  updateInitialValue() {
    this.setState({ initialValue: null })
  }

  setFiltersFromURL() {
    let tempParams = getURLParameters()
    let overrideSort = this.props.searchOptions ? this.props.searchOptions.defaultSort : null
    this.props.setFilters(tempParams.filters)

    let sortBy = getParameterByName('sort')

    if (sortBy) {
      this.props.setSort(tempParams.sort)
    } else if (overrideSort) {
      this.props.setSort(overrideSort)
    }
  }

  hideBlock() {
    if (this.props.searchItemCount === 0 && initialDisplay && this.props.searchOptions.isBlock) {
      return (this.props.flow === 'fad' || this.props.flow === 'sad')
    }
    initialDisplay = false
    return false
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleStickyElements)
    window.removeEventListener('popstate', this.setFiltersFromURL)
  }

  incrementSuperResultsListKey() {
    let newKey = this.state.superResultsListKey + 1
    this.setState({ superResultsListKey: newKey })
  }

  setFadResultsScrollPositions(options) {
    let crnRef = (window.innerWidth < 1200) ? this.fadSuperListRef :  this.mapScrollRef
    if (options === 'save' && !this.state.isLocationListLoading && !this.state.selectedProvider && crnRef.current) {
      let newPositions = { main: this.state.fadResultsScrollPositions.main, selectedLocation: this.state.fadResultsScrollPositions.selectedLocation }
      if (this.state.selectedLocation) {
        newPositions.selectedLocation = crnRef.current.scrollTop
      }
      else if (!this.state.selectedLocation) {
        newPositions.main = crnRef.current.scrollTop
      }
      this.setState({ fadResultsScrollPositions: newPositions })
    }
    else if (options === 'main' && crnRef.current) {
      crnRef.current.scrollTop = this.state.fadResultsScrollPositions.main
    }
    else if (options === 'loc' && crnRef.current) {
      crnRef.current.scrollTop = this.state.fadResultsScrollPositions.selectedLocation
    }
    else if (options === 'back' && crnRef.current) {
      let newPositions = { main: this.state.fadResultsScrollPositions.main, selectedLocation: this.state.fadResultsScrollPositions.selectedLocation }
      if (this.state.selectedLocation && !this.state.isLocationListLoading && !this.state.selectedProvider) {
        crnRef.current.scrollTop = this.state.fadResultsScrollPositions.selectedLocation
      }
      else if (!this.state.selectedLocation && !this.state.isLocationListLoading && !this.state.selectedProvider) {
        crnRef.current.scrollTop = this.state.fadResultsScrollPositions.main
      }
    }
  }

  setMapState(map) {
    this.setState({mapFAD: map})
  }

  setAddMapListenersState(addMapListeners) {
    this.setState({ addMapListeners: addMapListeners })
  }

  setZoomChangedListenerRef(zoomChangedListenerRef) {
    this.setState({zoomChangedListenerRef: zoomChangedListenerRef})
  }

  setMarkerClustererState(markerClusterer) {
    this.setState({markerClustererFAD: markerClusterer})
  }
  setFADPreliminaryStatus(value) {
    this.setState({ fadPreliminaryStatus: value })
  }

  setMarkersState(markers) {
    this.setState({ mapMarkersFAD: markers })
  }

  setSelectedProvider(selectedProvider, locations) {
    if (selectedProvider) {
      let keysToRemove = selectedProvider.addresses.map(address => address.id)
      if (selectedProvider.address) keysToRemove.push(selectedProvider.address.id)
      this.handleItemMouseLeave(keysToRemove)
    }

    let markersObj = []
    if (locations !== null) {
      let primKey = selectedProvider && selectedProvider.address ? selectedProvider.address.id : null
      markersObj = this.hideFADMapMarkers(locations, primKey)
      this.setState({ hiddenMapMarkersFAD: markersObj.hiddenMarkers, mapMarkersFAD: [], defaultLocationIcons: markersObj.defaultLocationIcons, hoveredLocationIcons: markersObj.hoveredLocationIcons })
    }

    this.setState({ selectedProvider: selectedProvider, locationsAtSelectedProvider: locations, isLocationListLoading: false})
  }

  hideFADMapMarkers(locationsAtSelectedProvider, primAddressKey = null) {
    let hiddenMarkers = []
    let positionList = []
    let defaultLocationIcons = []
    let hoveredLocationIcons = []

    google.maps.event.removeListener(this.state.zoomChangedListenerRef)
    google.maps.event.clearListeners(this.state.mapFAD, 'dragend');
    this.setState({ addMapListeners: false })

    if (this.state.mapMarkersFAD && this.state.mapMarkersFAD.length > 0) {
      for (let marker of this.state.mapMarkersFAD) {
        hiddenMarkers.push(marker)
        marker.setMap(null)
      }
    }

    if (this.state.markerClustererFAD !== null) {
      this.state.markerClustererFAD.removeMarkers(hiddenMarkers)
    }

    for (let location of locationsAtSelectedProvider) {
      let latitude
      let longitude
      let markerAddressKey
      if (location.AddressKey) {
        latitude = parseFloat(location.Latitude)
        longitude = parseFloat(location.Longitude)
        markerAddressKey = location.AddressKey
      }
      else {
        latitude = parseFloat(location.lat)
        longitude = parseFloat(location.lng)
        markerAddressKey = location.id
      }

      let imageURL = null
      let locationUrl = null
      if(this.props.flow!='mam'){
        if (location.ImageUrl && Array.from(location.ImageUrl)[0] !== '/') { imageURL = `/${location.ImageUrl}` }
         locationUrl = `https://www.ohiohealth.com/${imageURL}` ? `https://www.ohiohealth.com/${imageURL}` : `https://www.ohiohealth.com/${URLS.defaultLocationImage}`
      }else {
        if (location.ImageUrl ) { imageURL = `${location.ImageUrl}` }
         locationUrl = imageURL
      }

      const position = new google.maps.LatLng(latitude, longitude)

      let locationIcons = createLocationIcon(locationUrl, position, this.state.mapFAD)

      locationIcons.defaultOverlay.AddressKey = markerAddressKey
      locationIcons.hoveredOverlay.AddressKey = markerAddressKey
      defaultLocationIcons.push(locationIcons.defaultOverlay)
      hoveredLocationIcons.push(locationIcons.hoveredOverlay)



      positionList.push(position)
    }


    let userLocation = null
    let displayBounds = new google.maps.LatLngBounds()
    let geoPermission = window.localStorage.getItem('geoPermission')

    for (let position of positionList) {
      displayBounds.extend(position)
    }

    if (navigator.geolocation && geoPermission && geoPermission === 'accepted') {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          userLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
          if (userLocation !== null) {
            displayBounds.extend(userLocation);
            this.state.mapFAD.fitBounds(displayBounds)
            let idleListener = google.maps.event.addListener(this.state.mapFAD, 'idle', () => {
              if (this.state.mapFAD.getZoom() > 17) {
                this.state.mapFAD.setZoom(17)
              }
              google.maps.event.removeListener(idleListener)
            })
          }
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            window.localStorage.setItem('geoPermission', 'false')
            GeolocationAcceptOrDeny('denied')
          };
        }
      );
    } else {
      this.state.mapFAD.fitBounds(displayBounds)
      let idleListener = google.maps.event.addListener(this.state.mapFAD, 'idle', () => {
        if (this.state.mapFAD.getZoom() > 17) {
          this.state.mapFAD.setZoom(17)
        }
        google.maps.event.removeListener(idleListener)
      })
    }

    return { hiddenMarkers: hiddenMarkers, defaultLocationIcons: defaultLocationIcons, hoveredLocationIcons: hoveredLocationIcons }
  }

  showHiddenMarkers() {
    for (let locationIcon of this.state.defaultLocationIcons) {
      locationIcon.setMap(null)
    }
    for (let locationIcon of this.state.hoveredLocationIcons) {
      locationIcon.setMap(null)
    }

    if (this.state.markerClustererFAD !== null) {
      this.state.markerClustererFAD.addMarkers(this.state.hiddenMapMarkersFAD)
    } else {
      for (let marker of this.state.hiddenMapMarkersFAD) {
        marker.setMap(this.state.mapFAD)
      }
    }

    let allMarkers = [ ...this.state.hiddenMapMarkersFAD ]

    let displayBounds = new google.maps.LatLngBounds()
    for (let marker of allMarkers) {
      displayBounds.extend(marker.getPosition())
    }
    if (this.state.previousSelectedLocations.length > 0) {
      this.state.previousSelectedLocations[0].marker.setIcon(hoveredMarkerIconPNG)
      this.state.previousSelectedLocations[0].marker.setZIndex(Number(google.maps.Marker.MAX_ZINDEX) + 5)
      this.state.mapFAD.setZoom(this.state.previousSelectedLocations[0].zoom)
      this.state.mapFAD.setCenter(this.state.previousSelectedLocations[0].center)
    }
    else if (this.state.previousFADMapBounds) {
      this.state.mapFAD.setZoom(this.state.previousFADMapBounds.zoom)
      this.state.mapFAD.setCenter(this.state.previousFADMapBounds.center)
    }
    else {
      this.state.mapFAD.fitBounds(displayBounds)
    }
    this.setState({ hiddenMapMarkersFAD: [], mapMarkersFAD: allMarkers, addMapListeners: true, defaultLocationIcons: null, hoveredLocationIcons: null, previousFADMapBounds: null })
  }


  setIsLocationListLoading(isLocationListLoading) {
    this.setState({ isLocationListLoading: isLocationListLoading})
  }

  removeFADMapMarkers() {
    if (this.state.mapMarkersFAD && this.state.mapMarkersFAD.length > 0) {
      for (let i = 0; i < this.state.mapMarkersFAD.length; i++) {
        this.state.mapMarkersFAD[i].setMap(null);
      }
      this.setState({
        mapMarkersFAD: []
      })
    }
  }

  setSelectedLocation(loc, marker, options = null) {
    this.setClickedProvider(null)
    let returnScrollTo = 'main'

    if (options === 'save') {
      let pushToPrevBounds = {
        bounds: this.state.mapFAD ? this.state.mapFAD.getBounds(): null,
        zoom: this.state.mapFAD ? this.state.mapFAD.getZoom(): null,
        center: this.state.mapFAD ? this.state.mapFAD.getCenter(): null
      }
      this.setState({ previousFADMapBounds: pushToPrevBounds })
    }
    if (this.state.selectedLocation && options === 'save') {
      let crn = this.state.previousSelectedLocations
      let pushToPrev = {
        loc: this.state.selectedLocation,
        marker: this.state.selectedMarker,
        bounds: this.state.mapFAD ? this.state.mapFAD.getBounds(): null,
        zoom: this.state.mapFAD ? this.state.mapFAD.getZoom(): null,
        center: this.state.mapFAD ? this.state.mapFAD.getCenter(): null
      }
      crn.push(pushToPrev)
      this.setState({previousSelectedLocations: crn})
    }
    else if (options === 'reuse' && this.state.previousSelectedLocations && this.state.previousSelectedLocations.length > 0) {
      returnScrollTo = 'loc'
      loc = this.state.previousSelectedLocations[0].loc
      marker = this.state.previousSelectedLocations[0].marker
      this.setState({previousSelectedLocations: []})
    }
    const callback = () => {
      if (options === 'reuse' || options === 'resetScroll') this.setFadResultsScrollPositions('back')
    }
    let oldKey = null
    let newKey = null
    if (this.state.selectedLocation) { oldKey = this.state.selectedLocation.AddressKey ? this.state.selectedLocation.AddressKey : this.state.selectedLocation.id }
    if (loc) { newKey = loc.AddressKey ? loc.AddressKey : loc.id }
    if (this.state.selectedMarker && (newKey === null || newKey !== oldKey)) {
      this.state.selectedMarker.setIcon(defaultMarkerIconPNG)
    }
    if (loc) {
      let providers = []
      let adrKeys = loc.AddressKey ? loc.AddressKey.split(/,\s*/) : [loc.id]
      for (let i = 0; i < this.props.searchItems.length; i++) {
        let prov = this.props.searchItems[i]
        if (this.props.flow === 'mam' && prov && adrKeys.find((key) => prov.Id ==key.trim())) {
            const keyfind = adrKeys.find((key) => prov.Id == key.trim())
          if (keyfind) {
            providers.push(prov)
          }
        }
        if (prov.address && adrKeys.find((key) => prov.address.id === key.trim())) {
          providers.push(prov)
        }
        else if (prov.addresses) {
          let pushed = false
          for (let j = 0; j < prov.addresses.length; j++) {
            let adr = prov.addresses[j]
            if (!pushed && adrKeys.find((key) => adr.id === key.trim())) {
              providers.push(prov)
              pushed = true
            }
          }
        }   

      }
      if (options === 'reset' && providers.length < 1) {
        this.setState({ selectedLocation: null, selectedMarker: null, providersAtLocation: null }, () => { setTimeout(() => { callback() }, 0) })
      }
      else {
        this.setState({ selectedLocation: loc, selectedMarker: marker, providersAtLocation: providers }, () => { setTimeout(() => { callback() }, 0) })
      }
    }
    else {
      this.setState({ selectedLocation: loc, selectedMarker: marker, providersAtLocation: null }, () => { setTimeout(() => { callback() }, 0) })
    }
  }

  setClickedProvider(prov) {
    let crnProv = this.state.clickedProvider
    if (prov === null) {
      if (crnProv) {
        this.handleItemMouseLeave(crnProv.addresses.map(address => address.id))
      }
      this.setState({clickedProvider: null})
    }
    else if (prov) {
      if (crnProv) {
        this.handleItemMouseLeave(crnProv.addresses.map(address => address.id))
      }
      if (crnProv && crnProv.npi === prov.npi) {
        this.setState({clickedProvider: null})
      }
      else {
        this.handleItemMouseEnter(prov.addresses.map(address => address.id))
        this.setState({clickedProvider: prov})
      }
    }
  }

  setClickedLocation(loc) {
    let crnLoc = this.state.clickedLocationCard
    if (loc === null) {
      if (crnLoc) {
        let locKey = crnLoc.AddressKey ? crnLoc.AddressKey : crnLoc.id
        this.updateLocationIconStyle(locKey, false)
      }
      this.setState({clickedLocationCard: null})
    }
    else if (loc) {
      if (crnLoc) {
        let locKey = crnLoc.AddressKey ? crnLoc.AddressKey : crnLoc.id
        this.updateLocationIconStyle(locKey, false)
      }
      let locInKey = loc.AddressKey ? loc.AddressKey : loc.id
      this.updateLocationIconStyle(locInKey, true)
      this.setState({clickedLocationCard: loc})
    }
  }

  handleItemMouseEnter(locationIds) {
    locationIds.forEach((id) => {
      this.updateMarkerStyle(id, true)
    })
  }

  handleItemMouseLeave(locationIds) {
    locationIds.forEach((id) => {
      this.updateMarkerStyle(id, false)
    })
  }

  preventResultBarDrag(mouseDownEvent) {
    mouseDownEvent.stopPropagation()
    mouseDownEvent.preventDefault()

    let moveEvent = mouseDownEvent.type === 'mousedown' ? 'mousemove' : 'touchmove';
    let endEvent = mouseDownEvent.type === 'mousedown' ? 'mouseup' : 'touchend';

    const onMouseMove = (mouseMoveEvent) => {
      mouseMoveEvent.stopPropagation()
      mouseMoveEvent.preventDefault()
    }

    const onMouseUp = (event) => {
      event.stopPropagation()
      event.preventDefault()
      document.body.removeEventListener(moveEvent, onMouseMove);
      document.body.removeEventListener(endEvent, onMouseUp);
    }

    document.body.addEventListener(moveEvent, onMouseMove, { passive: false });
    document.body.addEventListener(endEvent, onMouseUp, { once: true, passive: false });
  }

  updateLocationIconStyle(addressKey, isHovered) {
    const { defaultLocationIcons, hoveredLocationIcons } = this.state
    for (let locationIcon of defaultLocationIcons) {
      let addressKeys = locationIcon.AddressKey.split(/,\s*/)
      if (locationIcon.AddressKey === addressKey) {
        isHovered ? locationIcon.setMap(null) : locationIcon.setMap(this.state.mapFAD)
      }
      else {
        for (let key of addressKeys) {
          if (key.trim() === addressKey) {
            isHovered ? locationIcon.setMap(null) : locationIcon.setMap(this.state.mapFAD)
            break
          }
        }
      }
    }
    for (let locationIcon of hoveredLocationIcons) {
      let addressKeys = locationIcon.AddressKey.split(/,\s*/)
      if (locationIcon.AddressKey === addressKey) {
        isHovered ? locationIcon.setMap(this.state.mapFAD) : locationIcon.setMap(null)
      }
      else {
        for (let key of addressKeys) {
          if (key.trim() === addressKey) {
            isHovered ? locationIcon.setMap(this.state.mapFAD) : locationIcon.setMap(null)
            break
          }
        }
      }
    }
  }

  updateMarkerStyle(addressKey, isHovered, options = null) {
    const setMarkerIcon = (marker) => {
      let icon
      let zIndex
      if (options && options.type === 'light-large') {
        zIndex = Number(google.maps.Marker.MAX_ZINDEX) + 5
        icon = selectedLightMarkerIconPNG
      }
      else if (isHovered) {
        zIndex = Number(google.maps.Marker.MAX_ZINDEX) + 50000
        icon = hoveredMarkerIconPNG
      }
      else {
        zIndex = Number(google.maps.Marker.MAX_ZINDEX) + 1
        icon = defaultMarkerIconPNG
      }

      marker.setZIndex(zIndex)
      marker.setIcon(icon)
    }

    const { mapMarkersFAD } = this.state
    for (let marker of mapMarkersFAD) {
      let markerAddressKeys = marker.AddressKey.split(/,\s*/)
      if (marker.AddressKey === addressKey) {
        setMarkerIcon(marker)
      }
      else {
        markerAddressKeys.forEach(key => {
          if (key.trim() === addressKey) {
            setMarkerIcon(marker)
          }
        })
      }
    }
  }

  setMobile() {
    this.setState({ mobile: !isDesktop(window.innerWidth) })
  }

  handleStickyElements(e) {
    if (this.filterOptions.makeFiltersSticky) {
      let bufferNumber = -20
      let boundings = document.getElementById('doctor-search-container') ? document.getElementById('doctor-search-container').getBoundingClientRect() : {}
      let filterBottom = document.getElementById('provider-filter-container') ? document.getElementById('provider-filter-container').getBoundingClientRect().bottom : {}
      let filterTop = document.getElementById('provider-filter-container') ? document.getElementById('provider-filter-container').getBoundingClientRect().top : {}
      let footerTop = document.getElementsByClassName('footer')[0] ? document.getElementsByClassName('footer')[0].getBoundingClientRect().top : null
      let stickyHeaderBottom = document.getElementsByClassName('fixed-filter-sort-header')[0] ? document.getElementsByClassName('fixed-filter-sort-header')[0].getBoundingClientRect().bottom : null

      if (boundings.bottom < bufferNumber && this.state.desktopFixed !== true && footerTop > 80) {
        this.setState({ desktopFixed: true })
      } else if (boundings.bottom > bufferNumber && this.state.desktopFixed !== false) {
        this.setState({ desktopFixed: false })
      } else if (stickyHeaderBottom !== null && footerTop < 80) {
        this.setState({ desktopFixed: false })
      }

      if (this.state.desktopBottom === false && filterBottom + 35 > footerTop && footerTop - 35 < window.innerHeight) {
        this.setState({ desktopBottom: true })
      } else if (this.state.desktopBottom === true && (footerTop - 35 > window.innerHeight || Math.sign(filterTop - 62) === 1)) {
        this.setState({ desktopBottom: false })
      }
    }
    this.setState({ innerHeight: window.innerHeight })
  }

  clearAllFilters(e) {
    e.stopPropagation()
    scrollToLocation('.find-a-doctor-page')
    if (this.props.sort === 'First Available' && this.props.filters && this.props.filters.selectedAvailability && this.props.filters.selectedAvailability.length > 0 && this.props.flow !== 'sad') {
      if (this.props.filters.selectedAvailability.includes('Doctors with Online Scheduling')) {
        this.props.showAlertModal(true, true)
      }
    } else {
      if (this.props.searchOptions && this.props.searchOptions.enableUrl) {
        insertBrowserHistory(insertEmptyFilters(getURLParameters()))
      }
      this.props.resetFilters()
    }
  }

  setShowSuperSortMobile(value) {
    this.setState({ showSuperSortMobile: value })
  }

  toggleAffiliatedDoctors() {
    $('html, body').animate({ scrollTop: 0 }, 'slow')
    this.props.toggleAffiliatedDoctors()
  }

  changeDesktopState(toggleFixed, toggleBottom) {
    this.setState({
      desktopFixed: toggleFixed,
      desktopBottom: toggleBottom
    })
  }

  generateDataContent() {
    let pageSize = 16
    if (this.state.showFilters) {
      pageSize = 15
    }
    if (this.props.flow === 'service') {
      pageSize = 6
    }

    return <div className={`${this.filterOptions.makeFiltersSticky ? 'sticky' : ''}`}>
      {this.props.showAffiliatedProviderMessage && !this.props.searchOptions.overrideAPP && <AffiliatedDoctorSection toggleAffiliatedDoctors={this.toggleAffiliatedDoctors} appCount={this.props.affiliatedProviderCount} docCount={this.props.filteredProviderCount} />}

      <Pagination
        flow={this.props.flow}
        handleStickyElements={this.handleStickyElements}
        changeDesktopState={this.changeDesktopState}
        searchItems={this.props.searchItems}
        isBlock={this.props.searchOptions.isBlock}
        enableUrl={this.props.searchOptions && this.props.searchOptions.enableUrl}
        providerCardOptions={this.props.providerCardOptions && this.props.providerCardOptions}
        showAffiliatedProviderCard={!this.props.showAffiliatedProviderMessage && !this.props.searchOptions.overrideAPP && this.props.affiliatedProviderCount > 0}
        toggleAffiliatedDoctors={this.toggleAffiliatedDoctors}
        pageSize={pageSize}
        setPageNumber={this.props.setPageNumber}
        allDoctors={this.props.allDoctors}
      />
    </div>
  }

  generateLoadingContent() {
    return this.props.flow !== 'service' ? (<div className='schedule-a-doctor-provider-results'>
      {
        this.props.flow === 'cin' ?
          this.state.mobile ? <React.Fragment>
            {Array.from({ length: 12 }).map((_, index) =>
            (<div key={`provider-box-${index}`} className='provider-box'>
              <SkeletonProviderCard />
            </div>)
            )}
          </React.Fragment>
            : (<table>
              <thead>
                <tr>
                  {Array.from({ length: 5 }).map((_, index) => (<th key={`th-${index}`}><SkeletonTableColumn /></th>))}
                </tr >
              </thead >
              <tbody>
                {Array.from({ length: 10 }).map((_, index) =>
                (<tr key={`tr=${index}`}>
                  {Array.from({ length: 5 }).map((_, tdi) => (<td key={`th-${index}-${tdi}`}><SkeletonTableColumn /></td>))}
                </tr>))}
              </tbody>
            </table >)
          : <React.Fragment>
            {Array.from({ length: 12 }).map((_, index) =>
            (<div key={`provider-box-${index}`} className='provider-box'>
              <SkeletonProviderCard />
            </div>)
            )}
          </React.Fragment>

      }
    </div>
    )
      : (<div className='doctor-search-provider-results pagination'>
        <div className='page-controls top hide-for-not-desktop' />
        <div className='loaded-results-container' style={{ display: 'block' }}>
          <div style={{ display: 'block' }} className='service-box'><SkeletonServiceCard key={1} /></div>
          <div className='service-box'><SkeletonServiceCard key={2} /></div>
          <div className='service-box'><SkeletonServiceCard key={3} /></div>
          <div className='service-box'><SkeletonServiceCard key={4} /></div>
          <div className='service-box'><SkeletonServiceCard key={5} /></div>
          <div className='service-box'><SkeletonServiceCard key={6} /></div>
          <div className='service-box'><SkeletonServiceCard key={7} /></div>
          <div className='service-box'><SkeletonServiceCard key={8} /></div>
          <div className='service-box'><SkeletonServiceCard key={9} /></div>
          <div className='service-box'><SkeletonServiceCard key={10} /></div>
          <div className='service-box'><SkeletonServiceCard key={11} /></div>
          <div className='service-box'><SkeletonServiceCard key={12} /></div>
        </div>
      </div>
      )
  }

  generateErrorContent(errorMessage, fetchRetry) {
    return (
      <div className='component-container'>
        <div className='message-bar-container'>
          <ErrorMessageBar text={errorMessage} retryHandler={fetchRetry} />
        </div>
      </div>
    )
  }

  mapContent() {
    return <div className='map-main-container'>
      {
        this.props.searchOptions.showSortBy &&
        <div className='sort-container sort-border'>
          <SearchSortConnector initialValue={null} disabled={!this.props.dataLoaded || this.props.filtersLoading || (this.props.searchItems && this.props.searchItems.length <= 1)} enableUrl={this.props.searchOptions.enableUrl} />
        </div>
      }
      {loadsData({
        dataLoaded: (!this.props.searched || this.props.dataLoaded) && !this.props.filtersLoading,
        dataFetchFailed: this.props.fetchCallFailed,
        generateDataContent: () => this.props.searchItems && this.props.searchItems.length > 0 ? <MultiLocationMap results={this.props.searchItems} /> : <NoResultsFoundConnector />,
        generateLoadingContent: () => <SkeletonMap />,
        generateErrorContent: () => this.generateErrorContent(LOCATION_ERROR_MESSAGE, () => { })
      })}

    </div>
  }

  mobileFilters() {
    return <div className={`hide-for-large filters-aside ${this.state.asideClass}`}>
      <div className='mobile-filter-title-container'>
        <SVGInline className='close-filters' svg={closeIcon} onClick={() => { togglePreventScroll(false); this.setState({ asideClass: 'slide-out' }) }} />
        <div className='mobile-filter-title'>Filter</div>
        {this.props.filterCount > 0 && <div className='mobile-filter-clear' onClick={(e) => this.clearAllFilters(e)}>Clear All</div>}
      </div>
      <div className='mobile-filters-container' style={{ height: this.state.innerHeight }}>
        {
          (this.props.flow === 'mam' ? this.state.dob : true) &&
          <FindFiltersConnector flow={this.props.flow}
            filterOptions={this.filterOptions}
            searchOptions={this.props.searchOptions}
            urlParameters={this.urlParameters.filters}
            dataLoaded={this.props.dataLoaded && !this.props.filtersLoading}
            mobile />
        }
        <button className='aside-btn' tabIndex='0' style={{ top: this.state.innerHeight - 55 }} onClick={() => { togglePreventScroll(false); this.setState({ asideClass: 'slide-out' }) }}>{this.props.filtersLoading || !this.props.dataLoaded ? 'LOADING' : `SEE ${this.props.searchItemCount}`} RESULTS</button>
      </div>
      <button className='aside-btn' tabIndex='0' style={{ top: this.state.innerHeight - 55 }} onClick={() => { togglePreventScroll(false); this.setState({ asideClass: 'slide-out' }) }}>{this.props.filtersLoading || !this.props.dataLoaded ? 'LOADING' : `SEE ${this.props.searchItemCount}`} RESULTS</button>
    </div>
  }

  mobileSuperFilters() {
    return (
      <div className={`super-filters-drawer hide-for-large ${this.state.asideClass}`}>
        <div className='mobile-super-filter-header'>
          <span>Filter</span>
          <SVGInline className='close-filters' svg={closeIcon} onClick={() => { togglePreventScroll(false); this.setState({ asideClass: 'slide-out' }) }} />
        </div>
        <div className='mobile-super-filter-main'>
          <FindFiltersConnector flow={this.props.flow}
            filterOptions={this.filterOptions}
            searchOptions={this.props.searchOptions}
            urlParameters={this.urlParameters.filters}
            dataLoaded={this.props.dataLoaded && !this.props.filtersLoading}
            mobile />
        </div>
        <div className='mobile-super-filter-footer'>
          <span className={`mobile-super-filter-clear ${this.props.filterCount === 0 ? 'disabled' : ''}`} onClick={this.props.filterCount > 0 ? (e) => { this.clearAllFilters(e) } : null}>Clear Filters</span>
          <button className='aside-btn' tabIndex='0' style={{ top: this.state.innerHeight - 55 }} onClick={() => { togglePreventScroll(false); this.setState({ asideClass: 'slide-out' }) }}>{this.props.filtersLoading || !this.props.dataLoaded ? 'LOADING' : `SHOW ${this.props.searchItemCount}`} RESULTS</button>
        </div>
      </div>
    )
  }

  results() {
    return <div>
      {
        (this.state.showFilters || this.props.searchOptions.showSortBy) &&
        <div id='filter-button-row-header' className={this.state.desktopFixed ? 'row-container fixed-filter-sort-header' : 'row-container button-row'}>
          {
            this.state.showFilters &&
            <div className='filters-container show-for-large'>
              <div className={`filter-header-label ${this.state.desktopFixed ? 'add-bottom-shadow' : ''}`}><span>Filters</span>
                {this.props.filterCount > 0 && <div className='clear-all-filters' onClick={(e) => this.clearAllFilters(e)}>Clear All</div>}
              </div>
            </div>
          }
          <div className={`sort-main-container ${this.state.desktopFixed ? 'add-bottom-shadow' : ''}`}>
            {this.state.showFilters && <button onClick={() => { togglePreventScroll(true); this.setState({ asideClass: 'slide-in' }) }} className='hide-for-large btn filter-btn'>Filter</button>}
            {
              this.props.searchOptions.showSortBy &&
              <div className='sort-container sort-border'>
                <SearchSortConnector initialValue={null} disabled={!this.props.dataLoaded || this.props.filtersLoading || (this.props.searchItems && this.props.searchItems.length <= 1)} enableUrl={this.props.searchOptions && this.props.searchOptions.enableUrl} flowType={this.props.flow} />
              </div>
            }
            {this.props.searchOptions.showSortBy && this.props.searchType === 'doctor' && (this.props.dataLoaded && !this.props.filtersLoading && this.props.searchItems && this.props.searchItems.length > 1) &&
              <div className='desktop-tip'>
                <div className='tooltip'>
                  <SVGInline className='tooltip-icon' svg={info} />
                  <div className='tooltiptext'>
                    <span>Sorting by first available appointment only applies to {this.props.searchOptions.isBlock && this.props.app ? 'providers' : 'doctors'} with online scheduling.</span>
                  </div>
                </div>
              </div>}
            <div className={this.state.desktopFixed ? 'show-fixed-doctor-count' : 'hide-filter'}>
              <span className='fixed-doctor-count'>{this.props.filteredProviderCount}</span>
              {searchCount(this.props.filteredProviderCount, this.props.flow, { isBlock: this.props.searchOptions.isBlock, app: this.props.app })} Found
            </div>
          </div>
        </div>
      }
      {this.props.searchOptions.showSortBy && this.props.sort === 'First Available' && this.props.searchType === 'doctor' && <div className='mobile-tip'>Only displaying {this.props.searchOptions.isBlock && this.props.app ? 'providers' : 'doctors'} with online scheduling.</div>}
      <div className='row-container content'>
        {
          this.state.showFilters && (this.props.flow === 'mam' ? this.state.dob : true) &&
          <div className={`filters-content show-for-large ${this.state.desktopFixed ? (this.state.desktopBottom ? 'filters-content-absolute' : 'filters-content-fixed') : ''}`}>
            <div id='provider-filter-container' className='filters-inner'>
              <div className='filter-group-container'>
                <FindFiltersConnector flow={this.props.flow}
                  filterOptions={this.filterOptions}
                  searchOptions={this.props.searchOptions}
                  urlParameters={this.urlParameters.filters}
                  dataLoaded={this.props.dataLoaded && !this.props.filtersLoading}
                  mobile={false} />
              </div>
            </div>
          </div>
        }
        <div className={`${this.state.desktopFixed ? 'results-container-fixed' : ''} ${this.props.sort ? this.props.sort.toLowerCase().replace(/\s/g, '-') : ''} ${this.props.providerCardOptions && this.props.providerCardOptions.displayAvailability ? 'display-first-available' : ''} results-container ${!this.state.showFilters ? 'full-width' : ''}`}>
          {
            this.props.dataLoaded && !this.props.filtersLoading && (this.props.flow === 'mam' && this.props.filteredProviderCount === 0 && this.props.affiliatedProviderCount === 0) && !this.props.fetchCallFailed
              ? <NoResultsFoundConnector isBlock={this.props.searchOptions.isBlock} flow={this.props.flow} />
              : (this.props.filteredProviderCount === 0 && this.props.affiliatedProviderCount === 0 && this.props.dataLoaded
                ? <FilteredTooFarConnector enableUrl={this.props.searchOptions && this.props.searchOptions.enableUrl} flow={this.props.flow} />
                : loadsData({
                  dataLoaded: (!this.props.searched || this.props.dataLoaded) && !this.props.filtersLoading,
                  dataFetchFailed: this.props.fetchCallFailed,
                  generateDataContent: this.generateDataContent,
                  generateLoadingContent: this.generateLoadingContent,
                  generateErrorContent: () => this.generateErrorContent(DOCTOR_ERROR_MESSAGE, this.props.fetchSchedulingDoctors)
                }))
          }
        </div>
      </div>
    </div>
  }

  superResults() {
    let selectedLocationName = ''
    if (this.state.selectedLocation) selectedLocationName = this.state.selectedLocation.SearchNameOverride ? this.state.selectedLocation.SearchNameOverride : this.state.selectedLocation.Name ? this.state.selectedLocation.Name : this.state.selectedLocation.lbl ? this.state.selectedLocation.lbl : ''
    if (selectedLocationName.length > 32) selectedLocationName = selectedLocationName.substring(0, 29) + '...'
    return (
      <div className='super-results-page--container'>
        {(!this.props.filtersLoading && this.props.dataLoaded) && this.props.flow !== 'mam' &&
          <FiltersRowConnector selectedProviderOrLoading={this.state.isLocationListLoading || this.state.selectedProvider} dataLoaded={this.props.dataLoaded && !this.props.filtersLoading} mobile={false} flow={this.props.flow} filterOptions={this.filterOptions} urlParameters={this.urlParameters.filters} searchOptions={this.props.searchOptions} resetFilters={this.props.resetFilters} filteredProviderCount={this.props.filteredProviderCount} />
        }
        <div id='fad-map-container' className={`fad-map-container ${this.props.flow === 'mam' ? 'fad-map-container-border' : '' }`}>
          {this.props.dataLoaded ?
            <div className='map-overlay' ref={this.mapScrollRef} onScroll={() => {this.setFadResultsScrollPositions('save')}}>
              <SuperSortConnector mapMarkersFAD={this.state.mapMarkersFAD} filteredProviderCount={this.props.filteredProviderCount} disabled={!this.props.dataLoaded || this.props.filtersLoading || (this.props.searchItems && this.props.searchItems.length <= 1)} enableUrl={this.props.searchOptions && this.props.searchOptions.enableUrl} selectedLocation={this.state.selectedLocation} setSelectedLocation={this.setSelectedLocation} providersAtLocationCount={this.state.providersAtLocation ? this.state.providersAtLocation.length : null} setSelectedProvider={this.setSelectedProvider} selectedProvider={this.state.selectedProvider} locationsAtSelectedProvider={this.state.locationsAtSelectedProvider} isLocationListLoading={this.state.isLocationListLoading} showHiddenMarkers={this.showHiddenMarkers}/>

              <div className='results-found' onTouchStart={this.preventResultBarDrag} onMouseDown={this.preventResultBarDrag}>
                {this.state.selectedProvider
                  ? <span className='results-found-details'>
                    {this.state.selectedProvider ? <a onTouchStart={() => {this.setSelectedProvider(null, null); this.showHiddenMarkers(); this.setSelectedLocation(null, null, 'reuse');}} onClick={() => {this.setSelectedProvider(null, null); this.showHiddenMarkers(); this.setSelectedLocation(null, null, 'reuse');}}><SVGInline svg={ArrowLeft} className='back-arrow' /></a> : null}
                    {this.state.locationsAtSelectedProvider ? (this.state.locationsAtSelectedProvider.length !== 1 ? `${this.state.locationsAtSelectedProvider.length} Locations with ${this.state.selectedProvider.title}` : `${this.state.locationsAtSelectedProvider.length} Location with ${this.state.selectedProvider.title}`) : null}
                  </span>
                  : (!this.state.isLocationListLoading && this.state.selectedLocation) ? <span className='results-found-details'>
                    {this.state.selectedLocation ? <a onTouchStart={() => {this.setSelectedLocation(null, null, 'resetScroll');}} onClick={() => {this.setSelectedLocation(null, null, 'resetScroll');}}><SVGInline svg={ArrowLeft} className='back-arrow' /></a> : null}
                    {this.state.providersAtLocation ? (this.state.providersAtLocation.length !== 1
                      ? `${this.state.providersAtLocation.length} Providers at ${selectedLocationName}`
                      : `${this.state.providersAtLocation.length} Provider at ${selectedLocationName}`) : null}
                  </span>
                    : `${this.props.filteredProviderCount} Providers at ${this.state.mapMarkersFAD ? this.state.mapMarkersFAD.length : '_'} Practice Locations`}
              </div>

              {this.props.allDoctors && this.props.allDoctors.length === 0 ? <NoResultsFoundConnector isBlock={this.props.searchOptions.isBlock} flow={this.props.flow} isSuperResults={this.props.searchOptions.isSuperResults}/>
                : (this.props.filteredProviderCount === 0 && this.props.affiliatedProviderCount === 0 && this.props.dataLoaded && !this.props.fetchCallFailed) ?
                  <FilteredTooFarConnector enableUrl={this.props.searchOptions && this.props.searchOptions.enableUrl} flow={this.props.flow} />
                  : (this.state.selectedLocation && this.state.providersAtLocation && this.state.providersAtLocation.length === 0  && this.props.dataLoaded && !this.props.fetchCallFailed) ?
                    <FilteredTooFarConnector enableUrl={this.props.searchOptions && this.props.searchOptions.enableUrl} flow={this.props.flow} isLocationSelected={true}/>
                    : <InfiniteScroll
                      key={this.state.superResultsListKey}
                      listRef={this.fadSuperListRef}
                      updateScrollPosition={() => {this.setFadResultsScrollPositions('save')}}
                      results={this.props.searchItems}
                      preliminaryStatus={this.state.fadPreliminaryStatus}
                      setPreliminaryStatus={this.setFADPreliminaryStatus}
                      clickedProvider={this.state.clickedProvider}
                      clickedLocation={this.state.clickedLocationCard}
                      selectedLocation={this.state.selectedLocation}
                      providersAtLocation={this.state.providersAtLocation}
                      setSelectedLocation={this.setSelectedLocation}
                      setSelectedProvider={this.setSelectedProvider}
                      selectedProvider={this.state.selectedProvider}
                      locationsAtSelectedProvider={this.state.locationsAtSelectedProvider}
                      setIsLocationListLoading={this.setIsLocationListLoading}
                      isLocationListLoading={this.state.isLocationListLoading}
                      updateLocationIconStyle={this.updateLocationIconStyle}
                      pageSize={7}
                      providerCardOptions={this.props.providerCardOptions && this.props.providerCardOptions}
                      enableUrl={this.props.searchOptions && this.props.searchOptions.enableUrl}
                      setPageNumber={this.props.setPageNumber}
                      mapScrollRef={this.mapScrollRef}
                      onMouseEnter={this.handleItemMouseEnter}
                      onMouseLeave={this.handleItemMouseLeave}
                      onClickProv={this.setClickedProvider}
                      onClickLoc={this.setClickedLocation}
                      sort={this.props.sort}
                      flow={this.props.flow}
                    />
              }
            </div>
            : <div className='loader'><LoadingElement /></div>
          }

          <div className='fad-map-view-container'>
          <FADMapConnector
              zoomChangedListenerRef={this.state.zoomChangedListenerRef}
              setZoomChangedListenerRef={this.setZoomChangedListenerRef}
              setAddMapListenersState={this.setAddMapListenersState}
              addMapListeners={this.state.addMapListeners}
              markerClustererFAD={this.state.markerClustererFAD}
              setMarkerClustererState={this.setMarkerClustererState}
              setMapState={this.setMapState}
              map={this.state.mapFAD}
              dataLoaded={this.props.dataLoaded}
              allDoctors={this.props.flow!='mam' ? this.props.allDoctors : []}
              searchResults={this.props.searchItems}
              urlParameters={this.props.urlParameters}
              parentProps={this.props}
              setMarkersState={this.setMarkersState}
              removeFADMapMarkers={this.removeFADMapMarkers}
              searchOptions={this.props.searchOptions}
              orgID={this.props.orgID}
              addressKeys={this.props.addressKeys}
              itemIds={this.props.itemIds}
              app={this.props.app}
              selectedLocation={this.state.selectedLocation}
              setSelectedLocation={this.setSelectedLocation}
              selectedMarker={this.state.selectedMarker}
              selectedProvider={this.state.selectedProvider}
              setSelectedProvider={this.setSelectedProvider}
              flow={this.props.flow}
            />
          </div>

        </div>
      </div>
    )
  }

  render() {
    return (
      <div className={`find-a-doctor-page ${this.props.searchOptions.isBlock && this.hideBlock() ? 'hide-block' : ''}`}>
        {
          !!this.props.searchOptions.showDoctorCount && !this.props.searchOptions.showLookingFor && !this.props.searchOptions.showLocation &&
          <div className='count-section'>
            <span className='number'>{this.props.searchItemCount}</span> <span>{searchCount(this.props.searchItemCount, this.props.flow, { isBlock: this.props.searchOptions.isBlock, app: this.props.app })} found</span>
          </div>
        }
        {this.props.flow === 'mam' &&
          <div id='locationResults' className='find-a-mamo-page'>
            { this.props.searchOptions.showLocation && <SearchBarConnector searchOptions={this.props.searchOptions} orgID={this.props.orgID} addressKeys={this.props.addressKeys} itemIds={this.props.itemIds} app={this.props.app} allSearchedDoctorsCount={(this.props.allDoctors) ? this.props.allDoctors.length : null} />}
            {<FiltersRowConnector 
            selectedProviderOrLoading={this.state.isLocationListLoading || this.state.selectedProvider} 
            dataLoaded={this.props.dataLoaded && !this.props.filtersLoading} 
            mobile={false} flow={this.props.flow} 
            filterOptions={this.props.filterOptions} 
            urlParameters={this.urlParameters.filters} 
            searchOptions={this.props.searchOptions} 
            resetFilters={this.props.resetFilters} 
            filteredProviderCount={this.props.filteredProviderCount} />}
          </div>
        }
        {(this.props.searchOptions.showLookingFor || this.props.searchOptions.showLocation) && this.props.flow !== 'mam' && <SearchBarConnector searchOptions={this.props.searchOptions} orgID={this.props.orgID} addressKeys={this.props.addressKeys} itemIds={this.props.itemIds} app={this.props.app} allSearchedDoctorsCount={(this.props.allDoctors) ? this.props.allDoctors.length : null} />}
        {this.props.flow === 'fad' && this.props.searchOptions.isSuperResults && <div className={`super-sort-main-container hide-for-large ${this.state.desktopFixed ? 'add-bottom-shadow' : ''}`}>
          {this.state.showFilters && !this.state.selectedProvider && !this.state.isLocationListLoading && <button onClick={() => { togglePreventScroll(true); this.setState({ asideClass: 'slide-in' }) }} className='hide-for-large btn filter-btn'>Filter</button>}
          {this.state.showFilters && (this.state.selectedProvider || this.state.isLocationListLoading) && <button className='hide-for-large btn filter-btn disabled'>Filter</button>}
          {this.props.searchOptions.showSortBy && !this.state.selectedProvider && !this.state.isLocationListLoading && <button onClick={() => { if (this.props.dataLoaded && !this.props.filtersLoading && !(this.props.searchItems && this.props.searchItems.length <= 1)) this.setShowSuperSortMobile(true); }} className='hide-for-large btn sort-btn'>Sort</button>}
          {this.props.searchOptions.showSortBy && (this.state.selectedProvider || this.state.isLocationListLoading) && <button className='hide-for-large btn sort-btn disabled'>Sort</button>}
          {this.props.searchOptions.showSortBy && this.props.searchType === 'doctor' && (this.props.dataLoaded && !this.props.filtersLoading && this.props.searchItems && this.props.searchItems.length > 1) &&
            <div className='desktop-tip'>
              <div className='tooltip'>
                <SVGInline className='tooltip-icon' svg={info} />
                <div className='tooltiptext'>
                  <span>Sorting by first available appointment only applies to {this.props.searchOptions.isBlock && this.props.app ? 'providers' : 'doctors'} with online scheduling.</span>
                </div>
              </div>
            </div>}
          <div className={this.state.desktopFixed ? 'show-fixed-doctor-count' : 'hide-filter'}>
            <span className='fixed-doctor-count'>{this.props.filteredProviderCount}</span>
            {searchCount(this.props.filteredProviderCount, this.props.flow, { isBlock: this.props.searchOptions.isBlock, app: this.props.app })} Found
          </div>
        </div>
        }
        {this.state.showFilters && this.props.flow === 'fad' && this.props.searchOptions.isSuperResults ? this.mobileSuperFilters() : this.mobileFilters()}
        {
          this.props.flow === 'fad' && this.props.searchOptions.isSuperResults && this.props.searchOptions.showSortBy &&
          <SuperSortMobileConnector showModal={this.state.showSuperSortMobile} setShowModal={this.setShowSuperSortMobile} showPrompt={true} promptDisabled={true} promptHidden={true} promptText='Sort' disabled={this.state.selectedProvider || !this.props.dataLoaded || this.props.filtersLoading || (this.props.searchItems && this.props.searchItems.length <= 1)} enableUrl={this.props.searchOptions && this.props.searchOptions.enableUrl} flowType={this.props.flow} />

        }
        {
          this.props.dataLoaded && (this.props.flow !== 'mam' && this.props.flow !== 'fad' && !this.props.searchOptions.isSuperResults && this.props.allDoctors && this.props.allDoctors.length === 0) && !this.props.fetchCallFailed
            ? <NoResultsFoundConnector isBlock={this.props.searchOptions.isBlock} flow={this.props.flow} />
            : (this.props.flow === 'mam' && this.props.displayType == 'super' ? this.superResults() : (this.props.displayType === 'map' ? this.mapContent() : (this.props.flow === 'fad' && this.props.searchOptions.isSuperResults ? this.superResults() : this.results())))
        }
        <BackToTop />
        <VideoModal />
        <FirstAvailModal searchItems={this.props.searchItems} enableUrl={this.props.searchOptions.enableUrl} />
      </div>
    )
  }
}
