import React, { useState, useEffect, useRef, useMemo } from "react";
import SVGInline from "react-svg-inline";
import BackArrow from "../../../../shared/assets/arrow-left.svg";
import CloseIcon from "../../../../shared/assets/x.svg";
import CalendarIcon from "../../../../shared/assets/calendar-drawers.svg";
import "./times-drawer.scss";
import moment from "moment";
import Modal from "../../../../shared/components/Modal";
import "../SkeletonDrawer/skeleton-drawer.scss";
import {
  AVAILABLE_APPOINTMENTS_ENDPOINT,
  AVAILABLE_MAMMO_APPOINTMENTS_ENDPOINT,
  MAMMO_VISIT_REGULAR,
} from "../../../../shared/constants/api-helpers";
import { fetchAPIData } from "../../../../shared/services/fetchData";
import { isOBGYN } from "../../../../shared/utility-functions/isOBGYN.js";
import SelectLocationBox from "../SelectLocationBox/index";
import arrowUp from "../../../../shared/assets/arrow-up.svg";
import arrowDown from "../../../../shared/assets/arrow-down.svg";
import { buildDoctorUrl } from "../../../../shared/utility-functions";
import { scheduleButtonEvent } from "../../../../shared/utility-functions/googleTagManager.js";
import locationPlaceholderSvg from "../../../../shared/assets/locationPlaceholder.svg";

const TimesDrawer = ({
  isSchedule = false,
  provider = null,
  flow,
  fadeOutType,
  onAnimationEnd,
  from,
  show,
  handleClose,
  handleCloseAll,
  scheduleLinkClick,
  mamLocation = null,
  onMamTimeClick,
  headerContent,
  subHeaderContent,
  isModifySchedule,
}) => {
  const [timesData, setTimesData] = useState(null);
  const [weeks, setWeeks] = useState(null);
  const [expandedDays, setExpandedDays] = useState({});
  const [isLoadingNewLocation, setIsLoadingNewLocation] = useState(false);
  const [isLoadingScrollData, setIsLoadingScrollData] = useState(false);
  const [currentLastDate, setCurrentLastDate] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [newScheduleTime, setNewScheduleTime] = useState(null);
  const [selectedChipIdx, setSelectedChipIdx] = useState("");
  const isSuperMammoScheduling = window.sessionStorage.getItem("isSuperMammoScheduling") || false;

  useEffect(() => {
    setNewScheduleTime(null);
    setSelectedChipIdx("");
  }, []);

  const timesDrawerRef = useRef();

  const today = moment();
  let initialStartDate = today.clone();
  let initialEndDate = moment().clone().add(2, "months");

  if (isSuperMammoScheduling && isModifySchedule) {
    const patientDob = moment(window.sessionStorage.getItem("qDate"));
    const diffYears = today.diff(patientDob, "years");
    const lastDob = patientDob.clone().add(diffYears, "years");
    const diffMonths = today.diff(lastDob, "months");
    if (diffYears == 39 && diffMonths == 11) {
      initialStartDate = patientDob.clone().add(40, "years");
      initialEndDate = initialStartDate.clone().add(2, "months");
    }
  }
  let initialStartDateString = initialStartDate.format("YYYY-MM-DD");
  let initialEndDateString = initialEndDate.format("YYYY-MM-DD");

  const locations = provider && provider.locations && provider.locations.length > 0 ? provider.locations : null;
  const address = provider && provider.address ? provider.address : null;
  let initialselectedLocation = "";
  let locationList = [];

  if (locations && locations.length > 0 && address !== null) {
    locationList = locations;
    for (let location of locations) {
      if (Math.abs(address.lat - location.latitude) <= 0.03 && Math.abs(address.lng - location.longitude) <= 0.03) {
        initialselectedLocation = location;
      }
    }
    if (initialselectedLocation === "") {
      initialselectedLocation = locations[0];
    }
  }

  const [showSelectLocationBox, setShowSelectLocBox] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(initialselectedLocation);

  let baseParams = useMemo(() => {
    if (flow === "mam") {
      return {
        endPoint: AVAILABLE_MAMMO_APPOINTMENTS_ENDPOINT,
        startDate: null,
        endDate: null,
        visitTypeId: window.sessionStorage.getItem("VisitTypeID") || MAMMO_VISIT_REGULAR,
        locationId: mamLocation.Id,
        weekIdentifier: null,
      };
    } else {
      return {
        endPoint: AVAILABLE_APPOINTMENTS_ENDPOINT,
        schedulingType: "Open",
        startDate: null,
        endDate: null,
        physicianId: provider ? provider.epicId : null,
      };
    }
  }, [flow, provider, mamLocation]);

  const getTimesDataOnScroll = async (startDateStringParam, oneWeekAheadStringParam) => {
    setIsLoadingScrollData(true);

    const params = {
      ...baseParams,
      startDate: startDateStringParam,
      endDate: oneWeekAheadStringParam,
      ...(flow === "mam" ? { locationId: mamLocation.Id } : { physicianId: provider.epicId }),
    };

    if (selectedLocation && selectedLocation.departmentId) {
      params.departmentId = selectedLocation.departmentId;
    }
    const visitTypeID = window.sessionStorage.getItem("visitTypeOBGYN");
    if (visitTypeID && isOBGYN(provider && provider.specialtyIds)) params.visitTypeId = visitTypeID;

    try {
      const response = await fetchAPIData(params);
      const uniqueMap = new Map(response.Dates.map((date) => [date.Date, date]));
      const uniqueTimes = Array.from(uniqueMap.values());
      setCurrentLastDate(oneWeekAheadStringParam);
      timesData === null ? setTimesData(convertTimesData(uniqueTimes)) : setTimesData((prevData) => [...prevData, ...convertTimesData(uniqueTimes)]);
      weeks === null
        ? setWeeks(createWeekList(startDateStringParam, oneWeekAheadStringParam))
        : setWeeks((prevData) => [...prevData, ...createWeekList(startDateStringParam, oneWeekAheadStringParam)]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoadingScrollData(false);
  };

  const getTimesDataOnLocationChange = async (startDateStringParam, oneMonthAheadStringParam) => {
    setIsLoadingNewLocation(true);

    const params = {
      ...baseParams,
      startDate: startDateStringParam,
      endDate: oneMonthAheadStringParam,
      ...(flow === "mam" ? { locationId: mamLocation.Id } : { physicianId: provider.epicId }),
    };

    if (selectedLocation && selectedLocation.departmentId) {
      params.departmentId = selectedLocation.departmentId;
    }
    const visitTypeID = window.sessionStorage.getItem("visitTypeOBGYN");
    if (visitTypeID && isOBGYN(provider ? provider.specialtyIds : null)) params.visitTypeId = visitTypeID;

    try {
      const response = await fetchAPIData(params);
      const uniqueMap = new Map(Array.isArray(response.Dates) ? response.Dates.map((date) => [date.Date, date]) : []);
      const uniqueTimes = Array.from(uniqueMap.values());

      setCurrentLastDate(oneMonthAheadStringParam);
      setTimesData(convertTimesData(uniqueTimes));
      setWeeks(createWeekList(startDateStringParam, oneMonthAheadStringParam));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoadingNewLocation(false);
  };

  useEffect(() => {
    getTimesDataOnLocationChange(initialStartDateString, initialEndDateString);
  }, [selectedLocation]);

  useEffect(() => {
    const handleClickOutsideDrawer = (event) => {
      if (timesDrawerRef.current && !timesDrawerRef.current.contains(event.target)) {
        handleCloseAll();
      }
    };

    document.addEventListener("mousedown", handleClickOutsideDrawer);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDrawer);
    };
  }, [timesDrawerRef]);

  const handleInfiniteScroll = (event) => {
    if (isLoadingScrollData) return;

    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    const threshold = 3000;
    if (scrollHeight - scrollTop - clientHeight <= threshold) {
      let startDateObj = new Date(currentLastDate + "T00:00:00");
      startDateObj.setDate(startDateObj.getDate() + 1);
      let newEndDateObj = new Date(startDateObj);
      newEndDateObj.setDate(newEndDateObj.getDate() + 7); // + 1 week
      let startDateString = `${startDateObj.getFullYear()}-${(startDateObj.getMonth() + 1).toString().padStart(2, "0")}-${startDateObj
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      let oneWeekAheadString = `${newEndDateObj.getFullYear()}-${(newEndDateObj.getMonth() + 1).toString().padStart(2, "0")}-${newEndDateObj
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      setIsLoadingScrollData(true);
      getTimesDataOnScroll(startDateString, oneWeekAheadString);
    }
  };

  const handleShowMore = (day) => {
    setExpandedDays((prevState) => ({ ...prevState, [day]: true }));
  };

  const convertTimesData = (data) => {
    let convertedData = [];

    const convertToStandardTime = (timesList) => {
      return timesList.map((timeObj) => {
        let time = timeObj.Time;
        let [hours, minutes] = time.split(":").map(Number);

        let period = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        if (hours === 0) {
          hours = 12;
        }
        minutes = minutes < 10 ? "0" + minutes : minutes;
        let standardTime = `${hours}:${minutes} ${period}`;
        return { ...timeObj, Time: standardTime };
      });
    };

    for (let dateTimeObj of data) {
      const newDate = new Date(dateTimeObj.Date + "T00:00:00");
      const convertedTimes = convertToStandardTime(dateTimeObj.Times);
      const newDateTimeObj = { Date: newDate, Times: convertedTimes };
      convertedData.push(newDateTimeObj);
    }

    return convertedData;
  };

  const createWeekList = (startDateString, endDateString) => {
    let startOfWeek = null;
    let endOfWeek = null;
    let weekList = [];
    let startDateObj = new Date(startDateString + "T00:00:00");
    let endDateObj = new Date(endDateString + "T00:00:00");
    const isWeekday = (dateObj) => {
      const dayOfWeek = dateObj.getDay();
      return dayOfWeek >= 1 && dayOfWeek <= 5;
    };
    const getNextMonday = (weekendDateObj) => {
      const newDate = new Date(weekendDateObj);
      const dayOfWeek = newDate.getDay();

      let daysToAdd = 0;
      if (dayOfWeek === 0) {
        daysToAdd = 1;
      } else if (dayOfWeek === 6) {
        daysToAdd = 2;
      }
      newDate.setDate(newDate.getDate() + daysToAdd);
      return newDate;
    };
    const getPreviousMonday = (weekdayDateObj) => {
      const newDate = new Date(weekdayDateObj);
      const dayOfWeek = newDate.getDay();

      let daysToSubtract = dayOfWeek - 1;

      newDate.setDate(newDate.getDate() - daysToSubtract);
      return newDate;
    };
    const getEndOfWeek = (startOfWeekObj) => {
      const newDate = new Date(startOfWeekObj);
      newDate.setDate(flow !== "mam" ? newDate.getDate() + 4 : newDate.getDate() + 6);
      return newDate;
    };
    while (startDateObj < endDateObj) {
      if (isWeekday(startDateObj)) {
        if (startDateObj.getDay() === 1) {
          // Monday
          startOfWeek = startDateObj;
          endOfWeek = getEndOfWeek(startOfWeek);
        } else {
          startOfWeek = getPreviousMonday(startDateObj);
          endOfWeek = getEndOfWeek(startOfWeek);
        }
      } else {
        startOfWeek = getNextMonday(startDateObj);
        endOfWeek = getEndOfWeek(startOfWeek);
      }
      let datesWithinWeek = [];
      for (let date = new Date(startOfWeek); date <= endOfWeek; date.setDate(date.getDate() + 1)) {
        datesWithinWeek.push({ Date: new Date(date) });
      }
      let newObj = { startOfWeek: startOfWeek, endOfWeek: endOfWeek, datesWithinWeek: datesWithinWeek };
      weekList.push(newObj);

      startDateObj = new Date(startOfWeek);
      startDateObj.setDate(startDateObj.getDate() + 7);
    }
    return weekList;
  };

  const weekListByFirstAvailable = (weekList) => {
    const firstAvailableWeekIndex = weekList.findIndex((week) => week.datesWithinWeek.some((day) => hasAvailableAppointments(day.Date)));
    const newWeekList = weekList.slice(firstAvailableWeekIndex);
    const currentDate = new Date(today.toDate().getTime()).setHours(0, 0, 0, 0);
    const startIndex =
      newWeekList[0].datesWithinWeek[0].Date <= currentDate &&
      newWeekList[0].datesWithinWeek[newWeekList[0].datesWithinWeek.length - 1].Date >= currentDate
        ? newWeekList[0].datesWithinWeek.findIndex((day) => day.Date.getTime() === currentDate)
        : 0;
    const filteredFirstWeek = newWeekList[0].datesWithinWeek.slice(startIndex);

    newWeekList[0].datesWithinWeek = filteredFirstWeek;

    return newWeekList;
  };

  const isSameDate = (date1, date2) => {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
  };

  const hasAvailableAppointments = (day) => {
    const timesDataDate = timesData.find(
      (data) => data.Date.getDate() === day.getDate() && data.Date.getMonth() === day.getMonth() && data.Date.getFullYear() === day.getFullYear()
    );

    return timesDataDate ? true : false;
  };

  const onProviderTimeClick = (provider, date, time) => {
    const dataLayerObject = {
      flow: flow,
      providerName: provider ? provider.title : "",
      providerID: provider ? provider.id : null,
      providerNPI: provider ? provider.npi : null,
      providerSpecialty: provider && provider.specialties ? provider.specialties.join(", ") : undefined,
      facilityName: undefined,
      facilityAddress: undefined,
    };

    scheduleButtonEvent(dataLayerObject);
    const openSchedulingURL = provider ? buildDoctorUrl("/schedule/", { name: provider.title, npi: provider.npi }) : null;

    const location = provider && provider.locations.find((loc) => loc.departmentId === time.DepartmentId);
    sessionStorage.setItem("fadSelectedLocation", JSON.stringify(location));

    let convertedTime = {
      DepartmentId: time.DepartmentId,
      Duration: time.Duration,
      Time: time.Time,
    };
    let newTime = moment(convertedTime.Time, "hh:mm a").format("HH:mm");
    convertedTime.Time = newTime;

    const appointment = {
      Date: date,
      Time: convertedTime,
    };
    sessionStorage.setItem("fadSelectedAppointment", JSON.stringify(appointment));
    sessionStorage.setItem("fadSelectedDoctor", JSON.stringify(provider));
    sessionStorage.setItem("search-path", window.location.href);

    scheduleLinkClick(openSchedulingURL);
    handleCloseAll();
  };

  let customClass = "bg-over-drawer";
  if (from === "card") {
    customClass = `${customClass} animate-in-slide`;
  }
  if (flow === "mam") {
    customClass = `${customClass} mam-background`;
  }
  if (fadeOutType === "times") {
    customClass = `${customClass} animate-out`;
  } else if (fadeOutType === "all") {
    customClass = `${customClass} animate-out-all-top`;
  }

  const getFirstAndLastOpenDays = () => {
    const daysOfWeek = [
      { OpenMon: "Monday" },
      { OpenTue: "Tuesday" },
      { OpenWed: "Wednesday" },
      { OpenThu: "Thursday" },
      { OpenFri: "Friday" },
      { OpenSat: "Saturday" },
      { OpenSun: "Sunday" },
    ];

    let firstOpenDay = null;
    let lastOpenDay = null;

    for (let dayObj of daysOfWeek) {
      const [key, value] = Object.entries(dayObj)[0];
      if (mamLocation[key]) {
        if (firstOpenDay === null) {
          firstOpenDay = value;
        }
        lastOpenDay = value;
      }
    }

    if (firstOpenDay && lastOpenDay) {
      return `Open ${firstOpenDay} - ${lastOpenDay}`;
    } else {
      return null;
    }
  };

  const checkImageLoad = (src, onSuccess, onError) => {
    const img = new Image();
    img.onload = onSuccess;
    img.onerror = onError;
    img.src = src;
  };

  useEffect(() => {
    if (flow === "mam") {
      if (mamLocation && mamLocation.LandscapePhotoURL) {
        checkImageLoad(
          mamLocation.LandscapePhotoURL,
          () => setImageLoaded(true),
          () => setImageLoaded(false)
        );
      } else {
        setImageLoaded(false);
      }
    }
  }, [flow, mamLocation]);

  return (
    <span
      className='skeleton-drawer-outer'
      onAnimationEnd={() => {
        onAnimationEnd();
      }}>
      <Modal
        showModal={show}
        hideCloseButton
        hideFunction={handleClose}
        customClass={customClass}>
        <div
          className='times-drawer-container'
          onScroll={(e)=>!isModifySchedule ? handleInfiniteScroll(e) : null}
          ref={timesDrawerRef}>
          {flow !== "mam" ? (
            <div className='blue-rectangle'>
              <div className='close-buttons'>
                <div
                  className='back-to-profile'
                  onClick={handleClose}>
                  <SVGInline
                    className='back-arrow'
                    svg={BackArrow}
                  />
                  <span className='back-to-profile-text'>Profile</span>
                </div>

                <div
                  className='close-all'
                  onClick={handleCloseAll}>
                  <SVGInline
                    className='close-all-x'
                    svg={CloseIcon}
                  />
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment>
              <div className='mam-location-image--container'>
                <div
                  className={`mam-close-all ${!imageLoaded ? "no-image-found" : ""}`}
                  onClick={handleCloseAll}>
                  <SVGInline
                    className='close-all-x'
                    svg={CloseIcon}
                  />
                </div>
                {imageLoaded ? (
                  <img
                    alt={mamLocation.Name}
                    src={mamLocation.LandscapePhotoURL}
                    aria-label={mamLocation.Name}
                    className='mam-location-image--url'
                  />
                ) : (
                  <SVGInline
                    className='mam-location-placeholder-image'
                    svg={locationPlaceholderSvg}
                  />
                )}
              </div>
              <div className='mam-location-details--container'>
                <div className='mam-location-name'>{mamLocation.Name}</div>
                <div className='mam-location-address'>
                  {mamLocation.Distance != null ? `${mamLocation.Distance} mi - ` : ""} {mamLocation.Address}, {mamLocation.City}
                </div>
                <div className='mam-location-days'>{getFirstAndLastOpenDays()}</div>
                <div className='mam-location-phone'>{mamLocation.Phone}</div>
              </div>
              <hr className='mam-location-divider' />
            </React.Fragment>
          )}

          <div className='avail-times-header'>{headerContent}</div>
          <div className={`avail-times-sub-header ${!subHeaderContent ? "d-none" : ""}`}>{subHeaderContent}</div>

          <div>
            {locationList && locationList.length > 0 ? (
              <div className='location-holder'>
                <div className='available-times-location-label name'>{selectedLocation.departmentName}</div>
                <div className='available-times-location-label street'>
                  {selectedLocation.address.street}, {selectedLocation.address.cityStateZip.split(",")[0]}
                </div>
              </div>
            ) : null}
            {locationList && locationList.length > 1 ? (
              <div
                className=' clickable mb-16 change-location-container'
                onClick={() => {
                  setShowSelectLocBox(!showSelectLocationBox);
                }}>
                <a className='clickable available-times-location-label change'>Change Location</a>
                {showSelectLocationBox ? (
                  <SVGInline
                    className='dropdown-arrow'
                    svg={arrowUp}
                  />
                ) : (
                  <SVGInline
                    className='dropdown-arrow'
                    svg={arrowDown}
                  />
                )}
              </div>
            ) : null}
            <SelectLocationBox
              selected={selectedLocation}
              setShowSelectLocBox={() => setShowSelectLocBox(!showSelectLocationBox)}
              setLocation={(newLoc) => {
                setSelectedLocation(newLoc);
              }}
              show={showSelectLocationBox}
              locationList={locationList}
            />
          </div>
          <div
            className='avail-times-section'
            onScroll={(e)=>isModifySchedule ? handleInfiniteScroll(e) : null}>
            {timesData &&
              weeks &&
              !isLoadingNewLocation &&
              weekListByFirstAvailable(weeks).map((week, index) => {
                let startMonth = week.startOfWeek.toLocaleString("default", { month: "long" });
                let endMonth = week.endOfWeek.toLocaleString("default", { month: "long" });
                let startDate = week.startOfWeek.getDate();
                let endDate = week.endOfWeek.getDate();

                return (
                  <div key={index}>
                    {flow != "mam" ? (
                      <div className='week-heading'>
                        {startMonth} {startDate} - {endMonth} {endDate}
                      </div>
                    ) : null}
                    {week.datesWithinWeek.map((date, index) => {
                      let dayName = date.Date.toLocaleString("en-US", { weekday: "long" });
                      let monthName = date.Date.toLocaleString("default", { month: "long" });
                      let newDate = date.Date.getDate();
                      let dayHasAvailableAppointments = timesData.find((x) => isSameDate(date.Date, x.Date) && x.Times && x.Times.length > 0);
                      return (
                        <div key={index}>
                          <div className='day-heading'>
                            {dayName}, {monthName} {newDate}
                          </div>
                          {dayHasAvailableAppointments ? (
                            <div>
                              {timesData.map((day, timesDataIndex) => {
                                if (isSameDate(day.Date, date.Date)) {
                                  return (
                                    <div
                                      key={timesDataIndex}
                                      className='time-slot-container'>
                                      {day.Times.slice(0, expandedDays[day.Date] ? day.Times.length : 14).map((time, index) => (
                                        <div
                                          key={index}
                                          className={`time-slot-chip-drawer ${
                                            selectedChipIdx == `${timesDataIndex}::${index}` ? "selected-time" : ""
                                          }`}
                                          onClick={() => {
                                            setSelectedChipIdx(`${timesDataIndex}::${index}`);
                                            setNewScheduleTime({ date: day.Date, time, provider });
                                            flow === "mam" ? onMamTimeClick(day.Date, time) : onProviderTimeClick(provider, day.Date, time);
                                          }}>
                                          {time.Time}
                                        </div>
                                      ))}

                                      {!expandedDays[day.Date] && day.Times.length > 14 && (
                                        <div
                                          className='time-slot-chip-drawer'
                                          onClick={() => handleShowMore(day.Date)}>
                                          More
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          ) : (
                            <div className='no-avail-times'>
                              <div className=''>
                                <SVGInline
                                  className='no-avail-times-calendar'
                                  svg={CalendarIcon}
                                />
                              </div>
                              <p>No available appointment times.</p>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}

            {(isLoadingScrollData || isLoadingNewLocation) && (
              <div className='skeleton'>
                <div className='grey-box' />
              </div>
            )}
          </div>
          {isSchedule ? (
            <div className='schdule-update-time-btn-container'>
              <button
                className='btn-cancel'
                onClick={handleCloseAll}>
                Cancel
              </button>
              <div className='flex-grow-1' />
              <button
                className='btn-update'
                onClick={() => {
                  onMamTimeClick(newScheduleTime.date, newScheduleTime.time, isSchedule);
                }}
                disabled={newScheduleTime ? false : true}>
                Update
              </button>
            </div>
          ) : null}
        </div>
      </Modal>
    </span>
  );
};

export default TimesDrawer;
