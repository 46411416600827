import React, { useState, useCallback } from 'react';
import { SuperResultsMamTimes } from './SuperResultsMamTimes.jsx'
import { connect } from 'react-redux'

const SuperResultsMamTimesConnector = (props) => {

const [fadeInType, setFadeInType] = useState('none');
const [fadeOutType, setFadeOutType] = useState('none');
const [timesDrawerShow, setTimesDrawerShow] = useState(false);

const removeRootScroll = () => {
  document.documentElement.classList.add('no-scroll-y');
};

const addRootScroll = () => {
  document.documentElement.classList.remove('no-scroll-y');
};

const openDrawer = () => {
  removeRootScroll();
  setFadeInType('card');
  setTimesDrawerShow(true);
};

const closeAllDrawers = () => {
  setTimesDrawerShow(false);
  addRootScroll();
};

const onDrawerAnimateEnd = useCallback(() => {
  if (fadeOutType === 'times') {
    setTimesDrawerShow(false);
  } else if (fadeOutType === 'all') {
    closeAllDrawers();
  }
  setFadeInType('none');
  setFadeOutType('none');
}, [fadeOutType]);

return (
  <SuperResultsMamTimes
    fadeInType={fadeInType}
    fadeOutType={fadeOutType}
    setFadeOutType={setFadeOutType}
    timesDrawerShow={timesDrawerShow}
    openDrawer={openDrawer}
    onDrawerAnimateEnd={onDrawerAnimateEnd}
    {...props}
  />
)
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
  }
}

export default connect(mapStateToProps, null, mergeProps)(SuperResultsMamTimesConnector)
